/* Vendor files ------------------------------------ */

import aos from './partial/aos';

// Placeholder delete on focus
$(function () {
	$('input,textarea')
		.focus(function () {
			$(this)
				.data('placeholder', $(this).attr('placeholder'))
				.attr('placeholder', '');
		})
		.blur(function () {
			$(this).attr('placeholder', $(this).data('placeholder'));
		});
});

$('h1, h2, h3, h4, h5, p').each(function () {
	var tekst = $(this).html();
	tekst = tekst.replace(/(\s)([\S])[\s]+/g, '$1$2&nbsp;'); //jednoznakowe
	tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, '$1$2&nbsp;'); //dwuznakowe
	$(this).html(tekst);
});
